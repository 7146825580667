/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery-1.8@1.8.3/jquery.min.js
 * - /npm/jquery.fancybox@2.1.5/source/jquery.fancybox.pack.min.js
 * - /npm/jquery-colorbox@1.6.4/jquery.colorbox.min.js
 * - /npm/flexslider@2.6.0/jquery.flexslider.min.js
 * - /npm/swiper@7.4.1/swiper-bundle.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
